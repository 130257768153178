import * as React from "react"
// import { Script,
//   //useStaticQuery,  
// } from "gatsby"

import {
  Segment,
  Container,
  Grid,
} from 'semantic-ui-react';

import TritonDefaultLayout from '../layouts/triton-default/default-layout'
import Seo from "../components/default/seo"
import GatsbyGoogleTag from "../components/triton-default/gatsby-google-tag"

//const GATSBY_GOOGLE_TAG = process.env.GATSBY_GOOGLE_TAG;

export const Head = () => {
  return (
    <>
      <Seo title="404 Not Found" />
      <GatsbyGoogleTag />
    </>
  )
}

const NotFoundPage = () => (
  <TritonDefaultLayout subpage={'404-page'}>
    <Segment basic style={{height: '100vh', background: 'rgb(18, 175, 222)' }}>
      <Container style={{marginTop: '100px'}}>
        <Grid centered stretched verticalAlign="middle" style={{height: '50vh', }}>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h1>Sorry! Page Not Found</h1>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </TritonDefaultLayout>
)

export default NotFoundPage
